<template>
  <q-page id="dashboard">
    <div class="q-pa-md">
      <div class="q-col-gutter-md row">
        <div
          class="col-12"
          style="margin-left: 10px;padding-right: 20px"
        >
          <small style="color: #616161">
            <q-btn-group flat>
              <q-btn
                flat
                round
                icon="chevron_left"
                class="white text-blue-7"
                clickable
                @click="changeMonth('prev')"
              />
              <q-btn flat :label="returnMonth" class="white text-blue-7" />
              <q-btn
                flat
                round
                icon="chevron_right"
                class="white text-blue-7"
                clickable
                @click="changeMonth('next')"
              />
            </q-btn-group>
          </small>

          <q-btn flat dense round icon="settings" size="12px" class="float-right">
            <q-menu anchor="top right" self="top right">
              <q-list class="text-grey-8" style="min-width: 100px">
                <q-item aria-hidden="true">
                  <q-item-section
                    class="text-uppercase text-grey-7"
                    style="font-size: 0.7rem"
                  >Opções</q-item-section>
                </q-item>
                <q-item clickable v-close-popup aria-hidden="true" @click="adicionarCard">
                  <q-item-section>Adicionar</q-item-section>
                </q-item>
                <q-item clickable v-close-popup aria-hidden="true" @click="atualizaDash">
                  <q-item-section>Atualizar</q-item-section>
                </q-item>
                <!-- <q-item clickable v-close-popup aria-hidden="true" @click="filtraDash">
                  <q-item-section>Filtrar Período</q-item-section>
                </q-item>-->
                <q-item clickable v-close-popup aria-hidden="true" @click="resetCards">
                  <q-item-section>Reajustar</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>

          <q-btn
            v-if="hasVideo"
            dense
            flat
            @click="modalNovoSistemaGrid"
            icon="ondemand_video"
            style="margin-right: 10px"
            class="float-right"
            size="12px"
          >
            <template v-slot:default>
              <q-tooltip>Novo Sistema de Grid</q-tooltip>
            </template>
          </q-btn>
        </div>

        <div class="col-12 col-md-12 text-center" v-if="fetchUser.error">
          <div>
            Ocorreu um erro ao carregar a Visão360. <a href="#" @click.prevent="$router.go()">Tentar novamente?</a>
          </div>
        </div>

        <div class="col-12 col-md-12 text-center" v-if="fetchUser.buscando">
          <q-spinner color="blue-7" size="4em" />
        </div>

        <div class="col-12" v-if="!fetchUser.buscando">
          <div v-if="$q.screen.gt.xs">
            <grid-layout
              :layout.sync="layout"
              :col-num="12"
              :row-height="30"
              :is-draggable="true"
              :is-resizable="false"
              :is-mirrored="false"
              :vertical-compact="true"
              :margin="[10, 10]"
              :use-css-transforms="true"
              :responsive="true"
              :auto-size="true"
              :breakpoints="{lg: 900, md: 792, sm: 500, xs: 300, xxs: 0}"
              >

              <grid-item
                v-for="(item, i) in layoutGrid"
                :key="i"
                :x="item.x"
                :y="item.y"
                :w="item.w"
                :h="item.h"
                :i="item.i"
                @moved="movedEvent"
                class="q-card style_scroll"
                :style="item.i === '0' || item.i === '1' || item.i === '8' || item.i === '7' ? 'overflow-x: auto' : ''"
                >
                <card-graf-receita v-if="!item.semPermissao && item.i === '0'"></card-graf-receita>
                <card-graf-despesa v-if="!item.semPermissao && item.i === '1'"></card-graf-despesa>
                <card-receita v-if="!item.semPermissao && item.i === '2'"></card-receita>
                <card-despesa v-if="!item.semPermissao && item.i === '4'"></card-despesa>
                <card-plano v-if="!item.semPermissao && item.i === '5'"></card-plano>
                <card-graf-ativo v-if="!item.semPermissao && item.i === '6'"></card-graf-ativo>
                <card-graf-despesa-categoria v-if="!item.semPermissao && item.i === '7'"></card-graf-despesa-categoria>
                <card-graf-email v-if="!item.semPermissao && item.i === '8'"></card-graf-email>
                <card-graf-clientes-forma-pagamento v-if="!item.semPermissao && item.i === '9'"></card-graf-clientes-forma-pagamento>
                <card-meu-estoque v-if="!item.semPermissao && item.i === '10'"></card-meu-estoque>
                <card-estoque-loterica class="card-dashboard" :estoqueEmpresa=estoqueEmpresa v-if="!item.semPermissao && item.i === '11'"></card-estoque-loterica>
                <card-meu-caixa v-if="!item.semPermissao && item.i === '12'"></card-meu-caixa>
                <card-caixas-abertos v-if="!item.semPermissao && item.i === '13'"></card-caixas-abertos>
                <card-contas-pagar-vencidas v-if="!item.semPermissao && item.i === '14'"></card-contas-pagar-vencidas>
                <card-contas-receber-vencidas v-if="!item.semPermissao && item.i === '15'"></card-contas-receber-vencidas>
                <card-receitas-forma-pagamento v-if="!item.semPermissao && item.i === '16'"></card-receitas-forma-pagamento>
                <card-contas-pagar-futuras v-if="!item.semPermissao && item.i === '17'"></card-contas-pagar-futuras>
                <card-contas-receber-futuras v-if="!item.semPermissao && item.i === '18'"></card-contas-receber-futuras>
                <card-relatorio-totalizadores v-if="!item.semPermissao && item.i === '19'"></card-relatorio-totalizadores>
                <card-sem-permissao v-if="item.semPermissao" :item="item"></card-sem-permissao>
              </grid-item>
            </grid-layout>
          </div>
          <div v-else>
            <div v-for="(item, i) in layoutGrid" :key="i">
              <q-card style="margin-bottom: 15px">
                <card-graf-receita v-if="!item.semPermissao && item.i === '0'"></card-graf-receita>
                <card-graf-despesa v-if="!item.semPermissao && item.i === '1'"></card-graf-despesa>
                <card-receita v-if="!item.semPermissao && item.i === '2'"></card-receita>
                <card-despesa v-if="!item.semPermissao && item.i === '4'"></card-despesa>
                <card-plano v-if="!item.semPermissao && item.i === '5'"></card-plano>
                <card-graf-ativo v-if="!item.semPermissao && item.i === '6'"></card-graf-ativo>
                <card-graf-despesa-categoria v-if="!item.semPermissao && item.i === '7'"></card-graf-despesa-categoria>
                <card-graf-email v-if="!item.semPermissao && item.i === '8'"></card-graf-email>
                <card-graf-clientes-forma-pagamento v-if="!item.semPermissao && item.i === '9'"></card-graf-clientes-forma-pagamento>
                <card-meu-estoque v-if="!item.semPermissao && item.i === '10'"></card-meu-estoque>
                <card-estoque-loterica :estoqueEmpresa=estoqueEmpresa v-if="!item.semPermissao && item.i === '11'"></card-estoque-loterica>
                <card-meu-caixa v-if="!item.semPermissao && item.i === '12'"></card-meu-caixa>
                <card-caixas-abertos v-if="!item.semPermissao && item.i === '13'"></card-caixas-abertos>
                <card-contas-pagar-vencidas v-if="!item.semPermissao && item.i === '14'"></card-contas-pagar-vencidas>
                <card-contas-receber-vencidas v-if="!item.semPermissao && item.i === '15'"></card-contas-receber-vencidas>
                <card-receitas-forma-pagamento v-if="!item.semPermissao && item.i === '16'"></card-receitas-forma-pagamento>
                <card-contas-pagar-futuras v-if="!item.semPermissao && item.i === '17'"></card-contas-pagar-futuras>
                <card-contas-receber-futuras v-if="!item.semPermissao && item.i === '18'"></card-contas-receber-futuras>
                <card-relatorio-totalizadores v-if="!item.semPermissao && item.i === '19'"></card-relatorio-totalizadores>
                <card-sem-permissao v-if="item.semPermissao" :item="item"></card-sem-permissao>
              </q-card>
            </div>
          </div>
        </div>
        <timeline-global></timeline-global>
        <adicionar-card-dashboard></adicionar-card-dashboard>
        <modal-detalhar-movconta></modal-detalhar-movconta>
      </div>

      <div id="root-wehelp" style="display: none;" ></div>
    </div>
  </q-page>
</template>

<script>
import VueGridLayout from "vue-grid-layout";
import { mapGetters } from "vuex";
import "../../public/css/font-awesome/css/font-awesome.min.css";
const moment = require("moment");

export default {
  name: "dashboard",
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem
  },
  meta: {
    title: "DSB | Visão 360º"
  },
  data() {
    return {
      referencia: moment().format("MM/YYYY"),
      screenWidth: null,
      dtini: moment()
        .startOf("month")
        .format("DD/MM/YYYY"),
      dtfim: moment()
        .endOf("month")
        .format("DD/MM/YYYY"),
      posting: false,
      hasVideo: false
    };
  },
  methods: {
    wehelp() {
      const user = this.$store.getters.dataUser;
      const empresa = this.$store.getters.dataEmpresa;

      const wehelpCustomerInfo = {
        debug: process.env.NODE_ENV === "production" ? 0 : 1,
        survey_token: "MDdiMWM0ZDdlNDMwYzAzMmQyNDdkNzZiNDg2ZDNkZWNiOTgyYjYzYjcxYjkzMjdlNTRhNmUwNmMyODY0NTc1Na8qY0H-y3dNs2udiWR90059qN2V_OkBqTol9n2mEqef",
        type: 'bar',
        message_open: 0,
        language: 'PORTUGUESE',
        company_unit: "82FIDY",
        experience_id: null,
        person: {
            internal_code: user.id,
            name: user.nome,
            email: user.email,
            type: "CUSTOMER",
            phone: user.celular,
            date_of_birth: "",
            language: "PORTUGUESE",
            created_at: moment(user.created).format('YYYY-MM-DD'),
            state: "",
            country: "BRA",
            company_unit: "1",
        },
        custom_fields: [
          {
            name: "CNPJ",
            value: empresa.cnpj
          },
          {
            name: "Razão",
            value: empresa.razao
          },
          {
            name: "Fantasia",
            value: empresa.fantasia
          }
        ]
      };

      loadWeHelpWidgetScreen('https://app.wehelpsoftware.com', wehelpCustomerInfo, 'root-wehelp');
    },
    movedEvent() {
      this.postLayout(this.layout);
    },
    postLayout(layout, reload = false, reajustar = false) {
      if (this.posting) return;
      this.posting = true;
      this.$store.dispatch("postLayout", {
        layout: layout,
        reload: reload,
        reajustar: reajustar,
        callback: () => {
          this.posting = false;
        }
      });
    },
    resetCards() {
      this.postLayout([], true, true);
      this.atualizaDash();
    },
    atualizaDash() {
      this.$store.dispatch("fetchUser");
      return;
    },
    adicionarCard() {
      this.$store.dispatch("openDialogAdicionarCard", true);
    },
    modalNovoSistemaGrid() {
      this.$store.dispatch("loadManual", "visao360");
    },
    verificarPrimeiroVideo() {
      if (!this.videosManuais || this.buscandoUser) return;

      this.hasVideo = this.manuaisDisponiveis.some(item => item === "visao360");

      if (!this.videosManuais.video_visao360) {
        this.modalNovoSistemaGrid();
      }
    },
    changeMonth(value) {
      let referencia = moment(this.referencia, "MM/YYYY");

      if (value === "prev") {
        referencia.subtract(1, "months");
      } else {
        referencia.add(1, "months");
      }

      this.referencia = referencia.format("MM/YYYY");

      let query = {};
      query.dtini = referencia.format("DD-MM-YYYY");
      query.dtfim = referencia.endOf('month').format("DD-MM-YYYY");
      this.$store.dispatch("fetchUser", query);
    },
    loadDataUser() {
      this.wehelp();
    },
    awaitingDataUser() {
      if (this.firstLoadDataUser) return;

      setTimeout(() => {
        if (!this.dataUser || !this.dataUser.email) {
          this.awaitingDataUser();
          return;
        }

        this.$store.commit("setFirstLoadDataUser", true);

        this.loadDataUser();
      }, 1000);
    },
  },
  computed: {
    ...mapGetters([
      "manuais",
      "fetchUser",
      "buscandoUser",
      "dataUser",
      "dataEmpresa",
      "firstLoadDataUser",
      "videosManuais",
      "manuaisDisponiveis"
    ]),
    chartData() {
      return this.fetchUser.db.despesasCategoria.data;
    },
    layout: {
      get() {
        // exibe o card de estoque apenas se tiver estoque
        return this.$store.getters.layout;
      },
      set() {}
    },
    layoutGrid() {
      return this.layout.filter(item => item.visivel === true || item.visivel === "true");
    },
    returnMonth() {
      return moment(this.referencia, "MM/YYYY").format("MMM/YYYY");
    },
    estoqueEmpresa(){
      return this.fetchUser.db.estoqueEmpresa;
    }
  },
  mounted() {
    this.$store.dispatch("fetchUser");
    window.addEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
    });
    this.verificarPrimeiroVideo();

    if (!this.firstLoadDataUser) {
      this.awaitingDataUser();
    }
  },
  watch: {
    manuais(val) {
      this.verificarPrimeiroVideo();
    },
  },
};
</script>

<style lang="stylus">
.style_scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
  /* border-radius: 10px; */
}

.style_scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #F5F5F5;
  border-radius: 10px;
}

.style_scroll::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 10px;
}

.card-dashboard{
  height: 100%;
  overflow: auto;
}
</style>
